<template>
  <div>
    <b-modal id="modal-2" title="Bidang/Balai Pelaksana" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <b-row>
        <b-col md="12">
          <b-alert variant="warning" show>
            <div class="alert-body">

              <h6><small class="text-primary">Sub Kegiatan</small><br><small><strong>{{ rsDetail.kode_kegiatan
                    }}</strong>
                  {{ rsDetail.nama_sub_kegiatan }}<strong> (Satuan {{ rsDetail.satuan }}) </strong></small> </h6>
              <h6><small class="text-primary">Indikator</small><br><small>
                  {{ rsDetail.indikatror }}
                </small></h6>

            </div>
          </b-alert>
        </b-col>
        <!-- <b-col md="4" class="my-1">
          <b-form-group abel-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            </b-input-group>
          </b-form-group>
        </b-col> -->
        <b-col md="8" class="my-1">
          <b-form-group>
            <v-select v-model="id_balaiBidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
              class="select-size-sm" :options="balai_bidang" />
          </b-form-group>
        </b-col>
        <b-col md="4" class="my-1">
          <b-button variant="primary" block size="sm" @click="tambahBalai()">
            <feather-icon icon="PlusCircleIcon" class="mr-50" size='15' /> Balai/Bidang
          </b-button>
        </b-col>


        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

            <template #cell(Aksi)="data">
              <b-row>
                <b-col md="5">
                  {{ data.item.nama }}<br>
                </b-col>
                <b-col md="6">
                  <h5 v-if="data.item.id_rejna_balai_bidang != id_rejna_balai_bidang">
                    <strong><sup>Rp. </sup> {{ Rp(data.item.pagu_anggaran) }}</strong>
                  </h5>
                  <b-row v-if="data.item.id_rejna_balai_bidang == id_rejna_balai_bidang">
                    <b-col md="10">
                      <b-form-group label="Pagu Anggaran" label-for="basicInput" size="sm">
                        <CurrencyInput id="basicInput" placeholder="" v-model="data.item.pagu_anggaran" />
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-button variant="info" size="sm" class="mt-2" @click="simpanPagu(data.item)">
                        <feather-icon icon="SaveIcon" size='15' />
                      </b-button>
                    </b-col>
                  </b-row>

                </b-col>
                <b-col md="1">
                  <b-badge v-if="data.item.id_rejna_balai_bidang != id_rejna_balai_bidang" variant="warning" size="sm"
                    @click="edit(data.item.id_rejna_balai_bidang)">
                    <feather-icon icon="EditIcon" size='15' />
                  </b-badge>
                  <b-badge variant="danger" size="sm" @click="HapusBalaiBidang(data.item.id_rejna_balai_bidang)"
                    class="mt-1">
                    <feather-icon icon="TrashIcon" size='15' />
                  </b-badge>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
            class="my-0" />
        </b-col>
      </b-row>

    </b-modal>

  </div>
</template>

<script>
import CurrencyInput from './CurrencyInput.vue'
import axios from '@/config/Axios'
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    CurrencyInput,
    BModal,
    BTable,
    vSelect,
    BAlert,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      balai_bidang: [],
      id_rejna_balai_bidang: {},
      id_balaiBidang: {},
      perPage: 19,
      pageOptions: [10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'Aksi',
        label: 'Bidang / Balai',
      },],
    }
  },
  mixins: [Base],
  mounted() {
    this.totalRows = this.items.length

  },
  emits: ["loadBalaiBidang"],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    edit(id_rejna_balai_bidang) {
      this.id_rejna_balai_bidang = id_rejna_balai_bidang
    },
    async simpanPagu(val) {
      if (confirm('Apakah anda yakin akan mengupdate Pagu Anggaran')) {
        const self = this;
        await axios({
          method: 'POST',
          url: '/api/pu/setup/renja/update_renja_balai_bidang',
          data: {
            id_renja: self.rsDetail.id_renja,
            pagu_anggaran: val.pagu_anggaran,
            id_rejna_balai_bidang: val.id_rejna_balai_bidang,
            id_balai_bidang: val.id_balai_bidang,

          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.items = response.data.result;
            self.$emit("loadBalaiBidang", '');
            self.id_rejna_balai_bidang = '0';
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    async tambahBalai() {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/setup/renja/insert_renja_balai_bidang',
        data: {
          id_balai_bidang: self.id_balaiBidang.id_bidang,
          id_renja: self.rsDetail.id_renja,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;
          self.$emit("loadBalaiBidang")
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async HapusBalaiBidang(id_rejna_balai_bidang) {
      if (confirm("apakah anda yakin akan menghapus data ini") == true) {
        const self = this;
        await axios({
          method: 'POST',
          url: '/api/pu/setup/renja/hapus_balai_bidang',
          data: {
            id_balai_bidang: self.id_balaiBidang.id_bidang,
            id_renja: self.rsDetail.id_renja,
            id_rejna_balai_bidang: id_rejna_balai_bidang
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.items = response.data.result;
            self.$emit("loadBalaiBidang")
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },


    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-2')
    }
  },
  props: {
    rsDetail: {},
    balaiBiang: {}
  },
  watch: {
    balaiBiang() {
      this.balai_bidang = this.balaiBiang;
    }
  },
  computed: {
    items: {
      get: function () {
        return this.rsDetail.balai_bidang;
      },
      set: function (newValue) {
        return this.rsDetail.balai_bidang = newValue;
      }
    },
    // balai_bidang: {
    //   get: function () {
    //     return this.balaiBiang;
    //   },
    //   set: function (newValue) {
    //     return this.balaiBiang = newValue;
    //   }
    // },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>