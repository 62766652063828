<template>
  <div>
    <b-modal id="modal-1" title="Detail Sub Kegiatan" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <b-row>
        <b-col md="12">
          <h6><small class="text-primary">Tujuan </small><br><small>
              {{ rsDetail.tujuan }} <br>
              <small class="text-primary">Indikator Tujuan </small><br> {{ rsDetail.indikator_tujuan }}
            </small></h6>
          <h6><small class="text-primary">Sasaran </small><br><small>
              {{ rsDetail.sasaran }}<br>
              <small class="text-primary">Indikator Sasaran </small><br>
              {{ rsDetail.indikator_sasaran }}
            </small></h6>
          <hr>
          <b-row>
            <b-col md="2">
              <h6><small><strong> {{ rsDetail.kode_program }}</strong></small></h6>
            </b-col>
            <b-col md="10">
              <h6><small> {{ rsDetail.nama_program }}</small></h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <h6><small><strong> {{ rsDetail.kode_kegiatan }}</strong></small></h6>
            </b-col>
            <b-col md="10">
              <h6><small> {{ rsDetail.nama_kegiatan }} </small></h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <h6><small><strong> {{ rsDetail.kode_sub_kegiatan }}</strong></small></h6>
            </b-col>
            <b-col md="10">
              <h6><small> {{ rsDetail.nama_sub_kegiatan }}</small></h6>
            </b-col>
          </b-row>
          <hr>
          <h6><small class="text-primary">Kinerja</small><br><small>
              {{ rsDetail.kinerja }}
            </small></h6>
          <h6><small class="text-primary">Indikator</small><br><small>
              {{ rsDetail.indikatror }}
            </small></h6>
          <h6> <small> <strong>Satuan {{ rsDetail.satuan }}</strong></small></h6>
        </b-col>
      </b-row>
      <b-alert variant="warning" show>
        <div class="alert-body">
          <b-row class="text-primary">
            <b-col md="12" v-for="balai in  rsDetail.balai_bidang" v-bind:key="balai.id_rejna_balai_bidang">
              <b-row style="margin-bottom: -10px; margin-top: -10px;">
                <b-col md="8">
                  <h6 class="text-primary"> <small><sub>Balai/Bidang</sub></small><br>{{ balai.nama }}<br>

                  </h6>
                </b-col>
                <b-col md="4">
                  <small><sub>Pagu Anggaran</sub></small><br>
                  <small style="font-size: 18px;;"><sup>Rp. </sup><strong style="float: right;">{{ Rp(balai.pagu_anggaran)
                  }}</strong> </small>
                </b-col>
              </b-row>
              <hr>
            </b-col>
          </b-row>
        </div>
      </b-alert>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BCard,
    BAlert, BRow, BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    rsDetail: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>