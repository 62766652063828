<template>
  <div>
    <b-row>
      <b-col md="9">
        <b-form-group label="Balai Bidang">
          <v-select v-model="id_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama"
            @input="tujuan_sasaran(id_bidang)" placeholder="Indikator dan Sasaran Program " :options="rsBalaiBidang" />
        </b-form-group>

        <b-form-group label="Tujuan Dan Sasaran">
          <v-select v-model="id_indikator" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="tujuan_sasaran"
            @input="load_program()" placeholder="Indikator dan Sasaran Program " :options="indikatorProgram" />
        </b-form-group>
      </b-col>
      <b-col md="3">

        <b-form-group label="." label-size="sm">
          <b-button variant="primary" block @click="load_data()" size="sm" class="mt-1">
            <feather-icon icon="RefreshCcwIcon" size='15' /> View All
          </b-button>
        </b-form-group>
        <h1 class="text-primary"><small>Total Pagu Anggaran </small><br>
          <sup>Rp.</sup><strong> {{ Rp(pagu_anggaran) }}</strong>
        </h1>

      </b-col>
      <b-col md="6">
        <b-form-group label="Program" label-size="sm">
          <v-select v-model="id_program" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_program"
            placeholder="Program " :options="program" @input=load_kegiatan(id_program) />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Kegiatan" label-size="sm">
          <v-select v-model="id_kegiatan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_kegiatan"
            placeholder="Kegiatan " :options="kegiatan" @input=load_renja(id_kegiatan) />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCard, BCol, BRow, BFormGroup, BButton
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab, BCard, BCol, BRow, vSelect, BFormGroup, BButton,
  },
  props: {
    indikatorProgram: {

    },
    program: {

    },
    kegiatan: {},
    rsBalaiBidang: {},
    pagu_anggaran: {}
  },
  data() {
    return {
      id_bidang: {},
      id_indikator: {},
      id_program: {},
      id_kegiatan: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ['load_program', 'load_kegiatan', 'load_renja', 'load_data', 'tujuan_sasaran'],
  methods: {
    tujuan_sasaran() {
      this.$emit('tujuan_sasaran', this.id_bidang)
      this.id_indikator = {};
      this.id_program = {};
      this.id_kegiatan = {};
    },
    load_data() {
      this.$emit('load_data')
      this.id_indikator = {}
    },
    load_program() {
      this.$emit('load_program', this.id_indikator.id_indikator)
      this.$emit('load_renja', this.id_indikator.id_indikator, '', '')
      this.id_program = {}
      this.id_kegiatan = {}
    },
    load_kegiatan() {
      this.$emit('load_kegiatan', this.id_program.id_program)
      this.$emit('load_renja', this.id_indikator.id_indikator, this.id_program.id_program, '')
      this.id_kegiatan = {}
    },
    load_renja() {
      this.$emit('load_renja', this.id_indikator.id_indikator, this.id_program.id_program, this.id_kegiatan.id_kegiatan)
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>