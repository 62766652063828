<template>
  <div>
    <b-modal id="modal-edit" title="Basic Modal" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- <b-form-group label="Tujuan">
              <validation-provider #default="{ errors }" rules="required" name="Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Indikator Tujuan">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <b-form-group label="Sasaran">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <b-form-group label="Indikator Sasaran">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- <b-form-group label="Program">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Kegiatan ">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <b-form-group label="Sub Kegiatan ">
              <validation-provider #default="{ errors }" rules="required" name="Indikator Tujuan">
                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_tujuan"
                  :options="tujuan" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>



            <hr>
            <b-col cols="12">
              <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm">
                <feather-icon icon="SaveIcon" class="mr-50" size='15' /> Submit
              </b-button>
              <b-button variant="warning" @click.prevent="close()" class="mr-1 ml-1" size="sm">
                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Batal
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal, BFormGroup, BFormInput, vSelect,
    BAlert, ValidationProvider, ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      tujuan: [
        { nama_tujuan: "Meningkatkan Kualitas Jalan Provinsi" }
      ]
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          alert('form submitted!')
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-edit')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>