<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-row>
          <b-col md="2">
            <b-avatar rounded="" size="40" variant="primary">
              <feather-icon icon="GridIcon" size='30' />
            </b-avatar>
          </b-col>
          <b-col md="9">
            <h4 class="text-primary">
              Balai/Bidang<br>Seksi Rancang Bangun
            </h4>
          </b-col>
        </b-row>

      </b-col>
      <!-- <b-col md="6"> -->
      <!-- <b-row>
          <b-col md="4">
            <h6><b-avatar rounded="" size="25" variant="light-primary"><feather-icon icon="GridIcon"
                  size='15' /></b-avatar> <strong class="text-warning " style="font-size: 20px;">0</strong>
              Peningkatan Jalan
            </h6>
            <h6><b-avatar rounded="" size="25" variant="light-primary"><feather-icon icon="GridIcon"
                  size='15' /></b-avatar> <strong class="text-warning  " style="font-size: 20px;">0</strong>
              Rehabilitasi Jalan
            </h6>
            <h6><b-avatar rounded="" size="25" variant="light-primary"><feather-icon icon="GridIcon"
                  size='15' /></b-avatar> <strong class="text-warning  " style="font-size: 20px;">0</strong>
              Rehab. Saluran
            </h6>
          </b-col>
          <b-col md="4">
            <h6><b-avatar rounded="" size="25" variant="light-primary"><feather-icon icon="GridIcon"
                  size='15' /></b-avatar> <strong class="text-warning  " style="font-size: 20px;">0</strong>
              Rehab. Saluran
            </h6>
            <h6><b-avatar rounded="" size="25" variant="light-primary"><feather-icon icon="GridIcon"
                  size='15' /></b-avatar> <strong class="text-warning " style="font-size: 20px;">0</strong>
              &nbsp;&nbsp;Rehap. DPT
            </h6>
          </b-col>
          <b-col md="4"></b-col>
        </b-row> -->
      <!-- </b-col> -->
      <b-col md="3">
        <h5 class=""><small><strong>Total Anggaran</strong></small><br>
          <span style="font-size: 30px;" class="mt-1 text-primary"><sup>Rp.</sup> <strong>2.652.062.000</strong></span>
        </h5>
      </b-col>
      <b-col md="5">
        <b-alert variant="primary" show style="margin-top: -10px; margin-bottom: -10px;;">
          <div class="alert-body">
            <b-row>
              <b-col md="3" class=" text-center">
                <small class=" text-center">Total Kegiatan</small>
                <h4 class=" text-center">
                  <span style="font-size: 40px;" class="mt-1 text-primary">
                    <strong>1</strong> </span>
                </h4>
              </b-col>
              <b-col md="9">
                <b-row>
                  <b-col md="6">
                    <h6><small><strong>0</strong> Peningkatan Jalan<br><strong>0</strong> Rehabilitasi
                        Jalan<br><strong>0</strong> Rehabilitasi Saluran<br><strong>0</strong> Rehabilitasi DPT
                      </small></h6>
                  </b-col>
                  <b-col md="6">
                    <h6><small><strong>0</strong> Rehabilitasi
                        Jembatan<br><strong>0</strong> Pengkatan Jembatan <br><strong>1</strong> Lainya</small>
                    </h6>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-alert>
      </b-col>


    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BAvatar, BAlert
} from 'bootstrap-vue'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAvatar, BAlert
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  methods: {
    async xxx() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>