<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <Filter_pencarian :rsBalaiBidang="rsBalaiBidang" :program="program" :kegiatan="rs_kegiatan"
            :indikatorProgram="indikatorProgram" @load_program=load_program @load_kegiatan=load_kegiatan
            @tujuan_sasaran="tujuan_sasaran" @load_renja=load_renja @load_data=load_data
            :pagu_anggaran=rsData.pagu_anggaran>
          </Filter_pencarian>
        </b-card>
        <b-card>
          <tabel_data :rsData=rsData.result @KeloalaBalaiBidang=KeloalaBalaiBidang @hapusRenja=hapusRenja></tabel_data>
        </b-card>
      </b-col>
    </b-row>
    <detail-sub-kegatan :rsDetail=rsDetail></detail-sub-kegatan>
    <detail-bidang-balai-pelaksana :rsDetail=rsDetail :balaiBiang=balaiBiang
      @loadBalaiBidang=loadBalaiBidang></detail-bidang-balai-pelaksana>
    <editsub-kegiatan :rsDetail=rsDetail></editsub-kegiatan>
    <tambah-sub-kegiatan :sasaran=sasaran :kegiatan=kegiatan @load_data=load_data></tambah-sub-kegiatan>
  </div>
</template>

<script>
import tabel_data from './component/tabel.vue'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCol, BRow,
  BTab, BCard, BButton
} from 'bootstrap-vue'
import detailSubKegatan from './component/detailSubKegatan.vue'
import detailBidangBalaiPelaksana from './component/detailBidangBalaiPelaksana.vue'
import EditsubKegiatan from './component/editsubKegiatan.vue'
import tambahSubKegiatan from './component/tambahSubKegiatan.vue'
import statistikrenja from './component/statistik_renja.vue'
import Filter_pencarian from './component/filter_pencarian.vue'
export default {
  components: {
    BTabs, BCol, BRow,
    BButton,
    BTab, tabel_data, BCard, detailSubKegatan, detailBidangBalaiPelaksana, EditsubKegiatan, tambahSubKegiatan, statistikrenja,
    Filter_pencarian
  },
  data() {
    return {
      rsData: [],
      rsDetail: {},
      idRenja: '',
      balaiBiang: [],
      thAnggaran: '',
      sasaran: [],
      kegiatan: [],
      indikatorProgram: [],
      program: [],
      rs_kegiatan: [],
      rsBalaiBidang: []
    }
  },
  mixins: [Base],
  mounted() {
    this.balaiBidang();
    this.load_data();
    this.th_angaran();
    this.get_sasaran();
    this.get_kegiatan();
    this.tujuan_sasaran();
    this.load_program();
    this.load_kegiatan();

  },
  methods: {
    async balaiBidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/laod_balai_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsBalaiBidang = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async tujuan_sasaran(id_bidang) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/tujuan_sasaran',
        data: {
          id_bidang: id_bidang.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.indikatorProgram = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_program(id_indikator) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/load_program',
        data: {
          id_indikator: id_indikator
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.program = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_kegiatan(id_program) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/load_kegiatan',
        data: {
          id_program: id_program
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_kegiatan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async load_renja(id_indikator, id_program, id_kegiatan) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/load_data',
        data: {
          id_indikator: id_indikator, id_program: id_program, id_kegiatan: id_kegiatan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    KeloalaBalaiBidang(val) {
      this.rsDetail = val;
      this.id_renja = val.id_renja;
      this.loadBalaiBidang();
      this.load_data();
    },
    async hapusRenja(val) {
      if (confirm("apakah yakin akan menghapus data ini") == true) {
        const self = this;
        await axios({
          method: 'POST',
          url: '/api/pu/setup/renja/hapus_renja',
          data: {
            id_renja: val.id_renja
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.notification('warning', "Info", response.data.message);
            self.load_data();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadBalaiBidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/get_balai_bidang',
        data: {
          id_renja: self.id_renja
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balaiBiang = response.data.result;
          self.load_data();
          self.balaiBidang();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async th_angaran() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/th_angaran',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.thAnggaran = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_sasaran() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/sasaran',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.sasaran = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_kegiatan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/setup/renja/get_kegiatan',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.kegiatan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>